export const Title: React.FC = (): JSX.Element => (
	<span className='home-title'>
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm <span className='h-red'>teresa pelinski</span> and this is my website
		<br />
		hi i'm teresa pelinski and this is my <span className='h-red'>website</span>
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
		hi i'm teresa pelinski and this is my website
		<br />
	</span>
)
